import React from "react"
import tw from "twin.macro"
import "typeface-quicksand"
import mainImage from "../../assets/img/mainImage.jpeg"

const FirstView = () => {
  return (
    <>
      <section tw="min-h-[100vh] flex justify-center items-center flex-1 flex-shrink-0 bg-gray-100 overflow-hidden shadow-lg  relative py-16 md:py-20 xl:py-48">
        {/* mainImage */}
        <img
          src={mainImage}
          loading="lazy"
          alt="Photo by Kouhei Yanagi"
          tw="w-full h-full object-cover object-center absolute inset-0"
        />
        {/* overlay */}
        <div tw="bg-indigo-500 mix-blend-multiply absolute inset-0"></div>
        {/* text start */}
        <div tw="sm:max-w-xl flex flex-col items-center relative p-2">
          <p tw="font-Quicksand text-indigo-200 text-lg sm:text-xl text-center mb-4 md:mb-8">
            BackImage location in Sakurashima, Kagoshima
          </p>
          <h1 tw="font-Quicksand text-white text-4xl sm:text-5xl md:text-7xl font-bold text-center mb-8 md:mb-12">
            Welcome to my Portfolio
          </h1>
        </div>
      </section>
    </>
  )
}

export default FirstView
