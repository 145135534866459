import React from "react"
import tw from "twin.macro"
import htmlSvg from "../../assets/img/html-5.svg"
import TodoImage from "../../assets/img/TodoAppHome.png"
import PortfolioImage from "../../assets/img/PortfolioSIte.png"
import RecPoolImage from "../../assets/img/RecPoolImage.png"

const CartType = ({ values }) => {
  const elems = values.map(elem => (
    <h3 tw="font-Quicksand  text-gray-500 pb-2 ">
      <span tw="bg-indigo-600 py-1 px-2 mx-1 text-white rounded whitespace-nowrap">
        {elem}
      </span>
    </h3>
  ))

  return (
    <p tw="text-[0.4rem] sm:text-xs lg:text-base text-gray-600 flex items-center flex-wrap ">
      {elems}
    </p>
  )
}

const CardTitle = props => {
  return (
    <p tw="font-Quicksand font-bold text-lg sm:text-xl lg:text-2xl py-2">
      {props.title}{" "}
    </p>
  )
}

const CardTime = props => {
  return (
    <div tw="flex items-center">
      <div tw="text-sm">
        <p tw="text-gray-600">{props.time}</p>
      </div>
    </div>
  )
}

const WorkCards = () => {
  return (
    <>
      <section tw="w-11/12 sm:w-9/12  my-5">
        <div tw="border border-gray-400 bg-white rounded p-4 my-2 leading-normal">
          <div tw="mb-8">
            <CartType
              values={[
                ["C++"],
                ["C#"],
                [".NET Framework"],
                ["WindowsForms App"],
                ["WPF"],
              ]}
            />
            <CardTitle title="WinFroms / WPFを用いたデスクトップアプリケーションの開発業務" />
            <div tw="font-Quicksand text-gray-700 text-xs lg:text-lg whitespace-pre-line">
              <h2>
                .NET Framework および WPF
                を用いた自社パッケージ製品のシステム開発に従事しています。
                <br tw="mb-4" />
                以下の業務内容を約3-4名程のメンバーでGitを用いてウォーターフォール型のチーム開発を行っています。
              </h2>
              <br />
              <ul tw=" justify-center inline-block ">
                <li>
                  📚新機能の画面実装・ロジック実装およびそれに伴うマスタ設定の変更
                </li>

                <li>
                  📚顧客様からの要望および問い合わせに対する改修作業およびパフォーマンス検証
                </li>
                <li>
                  📚C++およびWin32APIを用いた画面制御を行うDLLファイルの作成
                </li>
              </ul>
            </div>
          </div>
          <CardTime time="June.2021 - Dec.2021" />
        </div>

        <div tw="border border-gray-400 bg-white rounded p-4 my-2 leading-normal">
          <div tw="mb-8">
            <CartType
              values={[
                ["HTML/CSS"],
                ["JavaScript"],
                ["jQuery"],
                ["C#"],
                ["ASP.NET MVC"],
                ["ASP.NET Web API"],
                ["SQL Server"],
                ["Git"],
              ]}
            />
            <CardTitle title="社内管理WEBアプリケーションの開発業務" />
            <div tw="font-Quicksand text-gray-700 text-xs lg:text-lg whitespace-pre-line">
              <h2 tw="py-3">
                新人研修の一環として、自社製品の顧客情報および契約情報を管理するWEBアプリケーションの開発業務に
                要件定義,DB設計,開発,運用まで 0 → 1 ベースで担当しました。
              </h2>
              <br />
              <h3>[課題]</h3>
              <p tw="pt-2 pb-4">
                社内の顧客管理および問い合わせ内容の管理等を全てExcelを行っていた為、
                社内における業務効率の向上のために制作しました。
              </p>
              <br />
              <h3>[技術選定]</h3>
              <ul tw="pt-2 pb-4">
                <li>フロントエンド : HTML&CSS / JavaScript / jQuery</li>
                <li>バックエンド : ASP.NET MVC / ASP.NET Web API</li>
                <li>DB : SQL Server</li>
              </ul>
              <br />
              <h3>[主な機能]</h3>
              <ul tw="pt-2 pb-4">
                <li>✅認証機能(ASP.NET Identity)</li>
                <li>
                  ✅顧客情報の作成,編集,削除機能(Web API / Entity framework /
                  Linq to SQL)
                </li>
                <li>
                  ✅顧客毎のサーバー構成(オンプレミス環境)やリモード情報の管理(Editor.jsライブラリ)
                </li>
                <li>
                  ✅自社製品の問い合わせおよび要望内容の作成,編集,削除(データ一覧にはDatatableライブラリを使用)
                </li>
                <li>
                  ✅契約書,請求書および送付書等をCSV形式で出力する帳票機能(
                  ClosedXmlライブラリ)
                </li>
              </ul>
            </div>
          </div>
          <CardTime time="July.2021 - Dec.2021" />
        </div>
        <div tw="border border-gray-400 bg-white rounded p-4 my-2 leading-normal">
          <div tw="mb-8">
            <CartType
              values={[
                ["React.js"],
                ["Gatsby.js"],
                ["Tailwind CSS"],
                ["Netlify"],
                ["Github"],
              ]}
            />
            <CardTitle title="ポートフォリオサイト制作" />

            <div tw="font-Quicksand text-gray-700 text-xs lg:text-lg whitespace-pre-line">
              <h2 tw="py-3">
                React.jsおよびフロントエンド学習の一環として、当ポートフォリオサイトを制作しました。
              </h2>
              <div tw="flex justify-center items-center">
                <img src={PortfolioImage} tw="w-10/12 max-w-xl py-4" />
              </div>
              <h3>[技術選定]</h3>
              <ul tw="pt-2 pb-4">
                <li>フロントエンド : Gatsby.js</li>
                <li>UIフレームワーク : Tailwind CSS</li>
                <li>ホスティングサービス : Netlify</li>
              </ul>
              <br />
              <p>
                静的サイトである事、またデータベースやバックエンドの考慮
                が要らずにフロントエンド分野の学習に集中できる事から Gatsby.js
                を採用しました。
              </p>
            </div>
          </div>
          <CardTime time="Dec.2021 - Jan.2022" />
        </div>
        <div tw="border border-gray-400 bg-white rounded p-4 my-2 leading-normal">
          <div tw="mb-8">
            <CartType
              values={[["React.js"], ["TypeScript"], ["MUI"], ["Firebase"]]}
            />
            <CardTitle title="個人開発サービス RecPool 制作(制作中)" />

            <div tw="font-Quicksand text-gray-700 text-xs lg:text-lg whitespace-pre-line">
              <h2 tw="py-3">
                バンドマンのメンバーを集める「メン募」のような
                HIPHOP楽曲制作メンバーを募集また参加できる サービス RecPool
                の開発を現在進行形で行っています。
              </h2>

              <div tw="flex justify-center items-center">
                <img src={RecPoolImage} tw="w-10/12 max-w-xl py-4" />
              </div>

              <h3>[現在の開発進行度]</h3>
              <ul tw="pt-2 pb-4">
                <li>
                  ✅React学習 (React Hooks,React Router, Redux Toolkit,Custom
                  Hooks)
                </li>
                <li>
                  ✅Firebase学習(No SQLの特性の理解 / Authentication / FireStore
                  Database / Storage / Hosting )
                </li>
                <li>✅要件定義およびワイヤーフレームの作成</li>
                <li>
                  ✅トップページおよび新規ユーザーのプロフィール作成ページ実装
                </li>
                <li>✅ユーザーページおよび編集ページ</li>
                <li>🔲新規コミュニティ作成ページ / 制作物投稿ページ 実装</li>
                <li>
                  🔲検索ページ実装(検索対象 : ユーザー / コミュニティ / 制作物)
                </li>
                <li>🔲フレンド機能 / 通知機能の実施</li>
              </ul>
            </div>
          </div>
          <CardTime time="Jan.2022 - Now" />
        </div>

        <div tw="border border-gray-400 bg-white rounded p-4 my-2 leading-normal">
          <div tw="mb-8">
            <CartType
              values={[
                ["React.js"],
                ["TypeScript"],
                ["laravel"],
                ["heroku"],
                ["Github"],
              ]}
            />
            <CardTitle title="個人開発「しくじりTODO」制作" />

            <div tw="font-Quicksand text-gray-700 text-xs lg:text-lg whitespace-pre-line">
              <h2 tw="py-3">
                作成したTODOリストからその日に達成できなかったTODOを「しくじりTODO」として皆でシェアするアプリです。
              </h2>

              <a href="http://shikuziri-todo-app.herokuapp.com/">
                しくじりTODO | 公式サイト
              </a>

              <div tw="flex justify-center items-center">
                <img src={TodoImage} tw="w-10/12 max-w-xl py-4" />
              </div>

              <p tw="py-3">
                フロントエンドとサーバーエンドのフレームワークを組み合わせたSPAアプリケーションの学習
                およびReact.jsとLaravelの理解度を深める目的で作成しました。
                Firebaseで認証回りはサボっていたので、認証に関してはいい勉強になりました。
              </p>

              <h3>[技術選定]</h3>
              <ul tw="pt-2 pb-4">
                <li>フロントエンド : React.js(TypeScript)</li>
                <li>サーバーエンド : Laravel</li>
                <li>DB : PostgreSQL</li>
                <li>インフラサービス : Heroku</li>
                <li>バージョン管理 : Github</li>
              </ul>

              <h3>[主な機能]</h3>
              <ul tw="pt-2 pb-4">
                <li>
                  Twitter連携のSPA認証機能(laravel Socialite/Laravel Sanctum)
                </li>
                <li>SPA認証によるユーザーのログインおよびログアウト</li>
                <li>TODOリストの作成および削除</li>
              </ul>

              <h3>[問題点]</h3>
              <p tw="py-3">
                サービスの拡散・アピールを高める上でOGPを活用したSNSシェア(OGP画像とTwitterカード)
                は必須であると考える為、OGP設定および動的OGPの生成を行いたいのですがどうにも
                現在の形じゃ実現が厳しそうなので、模索中です。近日、Next.js ×
                Laravel でリプレイスして動的OGPを実装したいと思ってます。
              </p>
            </div>
          </div>
          <CardTime time="Feb.2022" />
        </div>
      </section>
    </>
  )
}

export default WorkCards
