import React, { useState, useEffect, useMemo } from "react"
import { merge } from "lodash"

type State = {
  transform: string
  opacity: number
}

const defaultState = (): State => ({
  transform: "",
  opacity: 0,
})

//フェードインアニメーションを実装するCustom Hook
//return => (CSSProperty)オブジェクト型
export const useScrollFadeIn = (
  ref: React.RefObject<HTMLDivElement>,
  translate: { x: number; y: number },
  adjust?: number
) => {
  const [state, updateStyleProperty] = useState<State>(
    merge(defaultState(), {
      transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
    })
  )

  const nodeStyle = useMemo(
    () => ({
      width: "100%",
      transition: "1.5s",
      opacity: state.opacity,
      transform: state.transform,
    }),
    [state.opacity, state.transform]
  )

  useEffect(() => {
    if (!ref.current) return
    const handleScroll = () => {
      //fadeIn させる DOM位置
      const offsetTop = ref.current.offsetTop

      if (offsetTop < window.pageYOffset - (adjust ? adjust : 0)) {
        updateStyleProperty(_state => ({
          ..._state,
          opacity: 1,
          transform: `translateX(0) translateY(0)`,
        }))
      } else {
        // ##１回のみの動きにするため処理を省略
        // ##条件外でStateを変更する場合は、この部分で更新関数を記述する
        //
        // <例>
        // //
        // updateStyleProperty(_state => ({
        //   ..._state,
        //   opacity: 0,
        //   transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
        // }))
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return {
    nodeStyle,
  }
}
