import React, { useRef } from "react"

import { useScrollFadeIn } from "./hooks"
import tw, { css } from "twin.macro"
import htmlSvg from "../../assets/img/html-5.svg"
import cssSvg from "../../assets/img/css-3.svg"
import jsSvg from "../../assets/img/javascript.svg"
import jquerySvg from "../../assets/img/jquery.svg"
import reactSvg from "../../assets/img/react.svg"
import gatsbySvg from "../../assets/img/gatsby.svg"
import cplusSvg from "../../assets/img/ISO_C++_Logo.svg"
import csharpSvg from "../../assets/img/c-sharp.svg"
import dotnetSvg from "../../assets/img/NET_Logo.svg"
import gitSvg from "../../assets/img/Git_icon.svg"

const Skills = () => {
  const ref = useRef(null)
  const { nodeStyle } = useScrollFadeIn(ref, { x: 0, y: 0 }, -400)

  return (
    <section tw="min-h-[60vh] bg-[#0099ff]">
      <div
        ref={ref}
        style={nodeStyle}
        tw="flex flex-col justify-center items-center"
      >
        <h1 tw="font-Quicksand text-white text-4xl  md:text-5xl pt-5 pb-4">
          - ⚙My Skills -
        </h1>
        <div tw="pt-20 pb-20 gap-4 w-4/5 sm:w-2/3 lg:w-1/2 flex flex-row flex-wrap justify-center ">
          <img src={htmlSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3  " />
          <img src={cssSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={jsSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={jquerySvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={reactSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={gatsbySvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={cplusSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3  " />
          <img src={csharpSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3  " />
          <img src={dotnetSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3 " />
          <img src={gitSvg} alt="" tw="w-16 mx-0 sm:mx-1 md:mx-3" />
        </div>
      </div>
    </section>
  )
}

export default Skills
