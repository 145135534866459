import React, { useRef } from "react"

import { useScrollFadeIn } from "./hooks"
import styled from "styled-components"
import tw, { css } from "twin.macro"

import WorkCards from "./WorkCards"

const Title = () => <h1 tw="font-Quicksand text-4xl py-2">- 📒Works -</h1>

const Works = () => {
  const ref = useRef(null)
  const { nodeStyle } = useScrollFadeIn(ref, { x: 0, y: 0 }, -400)
  return (
    <>
      <div
        ref={ref}
        style={nodeStyle}
        tw="items-center  flex flex-col justify-center"
      >
        <Title />
        <WorkCards />
      </div>
    </>
  )
}

export default Works
