import React, { useRef } from "react"
import { useScrollFadeIn } from "./hooks"
import myPhoto from "../../assets/img/myPhoto.jpg"
import QiitaIcon from "../../assets/img/qiitaIcon.png"
import myIcon from "../../assets/img/icon.png"
import tw from "twin.macro"

const LocationSvg = () => {
  return (
    <>
      <svg
        tw="fill-current text-gray-500 w-3 h-3 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
      </svg>
    </>
  )
}

const GithubSvgButton = () => {
  return (
    <>
      <a
        href="https://github.com/yanachuwan9sm"
        tw="text-gray-500 hover:text-gray-600"
      >
        <svg
          tw="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </a>
    </>
  )
}

const QiitaSvgButton = () => {
  return (
    <>
      <a
        href="https://qiita.com/ki-tamago"
        tw="text-gray-500 hover:text-gray-600"
      >
        <img src={QiitaIcon} tw="w-7 h-7" />
      </a>
    </>
  )
}

const Profile = () => {
  const ref = useRef(null)
  const { nodeStyle } = useScrollFadeIn(ref, { x: 0, y: 0 }, -400)

  return (
    <section
      ref={ref}
      style={nodeStyle}
      tw="min-h-[80vh] md:pt-20 flex flex-col justify-center items-center"
    >
      <h1 tw="font-Quicksand text-gray-800 text-4xl md:text-5xl pt-6 pb-4">
        - 👤Profile -
      </h1>

      <div tw="my-3">
        <img
          src={myIcon}
          tw="rounded-full border-indigo-500/50 h-40 w-40 my-3"
        />
        <h3 tw="font-Quicksand text-gray-700 text-2xl">Kouhei Yanagi</h3>
        <p tw="justify-center flex items-center text-sm text-gray-600 ">
          <LocationSvg />
          Nagasaki, Japan
        </p>
        <div tw="justify-center flex items-center space-x-2 mt-4 mb-7">
          <GithubSvgButton />
          <QiitaSvgButton />
        </div>
      </div>

      <div tw="p-4 bg-gray-100 rounded">
        <ul tw="font-Quicksand text-xs sm:text-sm md:text-base justify-center inline-block ">
          <li>🏠長崎県長崎市出身</li>
          <li>🏫2021年3月 鹿児島大学 工学部 情報生体システム工学科 卒業</li>
          <li>
            👜2021年4月 ソフトウェアベンダー系自社開発企業に開発SEとして配属
          </li>
          <li>👜2022年1月 一身上の都合により退社</li>
          <li>🏠2022年2月 長崎に移住しました</li>
        </ul>
      </div>
    </section>
  )
}

export default Profile
