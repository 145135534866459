import React from "react"

import styled from "styled-components"
import tw from "twin.macro"

import Skills from "../components/Skills"
import Works from "../components/Works"
import FirstView from "../components/FirstView"
import Profile from "../components/Profile"
import { useScrollFadeIn } from "../components/hooks"

const TopWave = () => {
  return (
    <svg
      tw="mb-[-3px]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#0099ff"
        fill-opacity="1"
        d="M0,224L60,213.3C120,203,240,181,360,197.3C480,213,600,267,720,266.7C840,267,960,213,1080,197.3C1200,181,1320,203,1380,213.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
      ></path>
    </svg>
  )
}

const BottomWave = () => {
  return (
    <svg
      tw="mt-[-3px]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#0099ff"
        fill-opacity="1"
        d="M0,128L60,122.7C120,117,240,107,360,96C480,85,600,75,720,90.7C840,107,960,149,1080,160C1200,171,1320,149,1380,138.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
      ></path>
    </svg>
  )
}

const Home = () => {
  return (
    <>
      <div id="main__scroll">
        <FirstView />
        <Profile />
        <TopWave />
        <Skills />
        <BottomWave />
        <Works />
      </div>
    </>
  )
}

export default Home
